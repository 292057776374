import React, { Component, PureComponent } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import './index.scss'

import LandingPage, {subMenuFilter} from './LandingPage'

import Butler from './Butler'
import Reservations from './Reservations'
// import OpenTable from './OpenTable'
// import ReservationsTmp from './ReservationsTmp'
import Form from './Form'
import FormReserve from './FormReserve'
import AutoMenu from './AutoMenu'


import './ThemeToggle/theme_night.scss'
import ThemeToggle from './ThemeToggle'

import CartBar from './CartBar'
import { injectShopContainer } from '../../Context/ShopData';
export {CartBar as StickyTopBar}

export const customOrderAppPaths = []


class TwoGuysFromItalyGlendale extends Component {
  render() {
    return (
      <div className="twoguysfromitalyglendale">
      </div>
    );
  }
}


export const pages = [
  {path: "/order", label: "Order", component: injectShopContainer(OrderingHeader)},
  // {path: "/dinein", label: "Patio Ordering", hidden: true, component: Butler},
  // {path: "/reserve", label: "Reservations", component: Reservations},
  // {path: "/reserve/:id/:name", label: "Reservations", hidden: true, component: Reservations},
 
  {path: "/menu", label: "Menu", component: Menu},
  {path: "https://www.ezcater.com/catering/pvt/mikaza-sushi-and-lounge-3",
  external: true,
   label: "Catering", component: Brunch},
  {path: "https://www.opentable.com/restref/client/?restref=1264333&lang=en-US&ot_source=Restaurant%20website&corrid=d2052dee-2fd5-4e96-8fd3-2542ab7b85ff",
  external: true,
   label: "Reserve", component: Brunch},
  // {path: "/salsanight", label: "Salsa Night", component: SalsaNight},
 
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, hidden, external}) => (
           <li key={path}>
           {external ?
             <a       className="page-link" href={path}>{label}</a> :
             <NavLink className="page-link" exact to={path}>{label}</NavLink>}
         </li>
        ))}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

function Menu () {
  return(
    <div className="page">
      <div
          className="page-header">

        <div className="text">
            <h1>Menu</h1>
            
          
        </div>
        
        </div>
{/* 
        <p
        style={{
          textAlign: 'center',
          padding: '1em 0.5em',
          fontWeight: 700
        }}
        >No Outside Drinks Allowed</p>    
        <p
        style={{
          textAlign: 'center',
          padding: '1em 0.5em',
          fontWeight: 700
        }}
        >Groups of 6 or more: 20% Gratuity added to bill</p>         */}
      <AutoMenu
        // disableOrder={true}
        subMenuFilter={subMenuFilter}
      ></AutoMenu>  
    </div>
  )
}

function Brunch () {
  return(
    <div className="page">
      <div
          className="page-header">

        <div className="text">
            <h1>Menu</h1>
            
          
        </div>
        
        </div>
{/* 
        <p
        style={{
          textAlign: 'center',
          padding: '1em 0.5em',
          fontWeight: 700
        }}
        >No Outside Drinks Allowed</p>    
        <p
        style={{
          textAlign: 'center',
          padding: '1em 0.5em',
          fontWeight: 700
        }}
        >Groups of 6 or more: 20% Gratuity added to bill</p>         */}
      <AutoMenu
        // disableOrder={true}
        tagsToShow={['brunch']}
      ></AutoMenu>  
    </div>
  )
}

function SalsaNight () {
  return(
    <div className="page">
      <div
          className="page-header">

        <div className="text">
            <h1>Salsa Night</h1>
            
          
        </div>
        
        </div>

      <AutoMenu
        // disableOrder={true}
        tagsToShow={['salsa-night']}
      ></AutoMenu>  
    </div>
  )
}

export function OrderingHeader ({settings={}}) {
  const {enableOrdering} = settings

    return (
      <div className="page">
        <div
          className="page-header">

        <div className="text">
            <h1>Pickup / Delivery</h1>
            {!enableOrdering &&
          <p>Not accepting orders at the moment, Join our email list for updates</p>
          }
{/* 
          <p
          style={{
            margin: "1.5em 0",
            lineHeight: "1.5em"
          }}
          >🌮🇵🇪 $1 Taco Tuesday Special: <a href="https://pablitostacos.com/dtla">Order Pickup / Delivery</a> from Pablitos Tacos DTLA Truck, Parked in front of Mikaza.</p>
          <p>Pablitos Tacos serves Peruvian x Mexican Taco</p> */}
        </div>

          {/* <div className="logo-wrapper">
            <Stout className="stout-header"></Stout>
          </div> */}
        </div>
        
        {!enableOrdering &&
          <Form></Form>}
        <AutoMenu
                  subMenuFilter={subMenuFilter}
        ></AutoMenu>
      </div>
    );
  
}


// export { OrderingHeader: injectShopContainer(OrderingHeader) }

const sections = [
  {
    title: "Happy Hour",
    content: "Every Day 3pm-7pm. All Day Saturday",
    backgroundImage: ""
  }
]

// logo link: https://d2abh92eaqfsaa.cloudfront.net/image/upload/u7ryxhrj9dapgz8pqmny
export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">

      <LandingPage></LandingPage>










      </div>
    );
  }
}

const foodImages = [
  'https://afag.imgix.net/two-guys-from-italy-glendale/meat-balls-pasta.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/porchiuto.jpg?w=800&auto=compress',
  'https://afag.imgix.net/two-guys-from-italy-glendale/pizza.jpg?w=800&auto=compress',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_d.jpg',
  'http://www.glendaletwoguysfromitaly.com/img/carousel/home_bg_e.jpg'
]

export default TwoGuysFromItalyGlendale;
