
import * as Components from "./Mikaza"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "mikaza"
}

